<template>
	<v-app>
		<v-app-bar
			height="115"
			elevation="1"
		>
			<img
				class="logo"
				:src="require(`@/assets/gb-mediensysteme-logo.png.webp`)"
				height="75"
			/>
		</v-app-bar>
		
		<v-main>
			<v-container>
				<v-card
					class="mx-auto my-4 pa-8 pb-4"
					max-width="1000px"
				>
					<p class="portal-info mb-8">
						Herzlich Willkommen auf unseren G+B Streaming Portalen. Informieren Sie sich hier über unsere Lösungen:
					</p>
					<div
						class="portal-link"
						v-for="element of streamingPortalLinks"
						:key="element.title"
						:disabled="element.disabled"
					>
						<v-btn
							fab
							large
							:disabled="element.disabled"
							color="error"
							@click="link(element.link)"
						>
							<v-icon large>mdi-gesture-tap</v-icon>
						</v-btn>
						<div
							class="mx-4"
						>
							<div
								class="portal-link__title"
							>
								{{ element.title }}
							</div>
							<div
								class="portal-link__description"
							>
								{{ element.description }}
							</div>
						</div>
						<v-spacer/>
						<v-btn
							fab
							x-small
							:disabled="element.disabled"
							color="error"
							@click="link(element.file)"
						>
							<v-icon>mdi-download</v-icon>
						</v-btn>
					</div>
					<div class="links mt-6">
						<div
							class="mx-auto"
						>
							<a
								href="https://www.gb-mediensysteme.de/impressum/"
								target="_blank"
							>
								Impressum
							</a>
							<div class="link-divider mx-3"></div>
							<a
								href="https://www.gb-mediensysteme.de/datenschutzerklaerung/"
								target="_blank"
							>
								Datenschutz
							</a>
						</div>
						
						<div
							class="mx-auto"
						>
							<a
								href="https://www.gb-mediensysteme.de/"
								target="_blank"
							>
								GAHRENS + BATTERMANN GmbH & Co. KG
							</a>
						</div>
					</div>
				</v-card>
			</v-container>
		</v-main>
		
		<!--
		<v-footer
			color="#ececec"
			padless
			absolute
		>
			<v-row
				justify="center"
				no-gutters
			>
				<v-btn
					v-for="element in footerLinks"
					:key="element.text"
					color="dark"
					text
					rounded
					class="my-2"
					@click="link(element)"
				>
					{{ element.text }}
				</v-btn>
			</v-row>
		</v-footer>
		-->
	</v-app>
</template>

<script>
export default
{
	name: 'App',
	
	created ()
	{
		document.title = 'G+B Portale';
	},
	
	methods:
	{
		link (link)
		{
			if (!link)
			{
				return;
			}
			
			window.open(link, '_blank');
		}
	},
	
	data: () =>
	(
		{
			streamingPortalLinks:
			[
				{
					title: 'G+B Basic Portal',
					description: 'Mit unseren Basis Funktionen: Agenda + Q&A + Chat + Like Funktion',
					link: 'https://basic-portal.eventvote.de',
					file: 'pdfs/Onepager_G+B_Basic Portal.pdf'
				},
				{
					title: 'G+B Business Portal',
					description: 'Mehr als Basic? Basis Funktionen + Speaker + Live-Voting + Mediathek',
					link: 'https://business-portal.eventvote.de',
					file: 'pdfs/Onepager_G+B_Business Portal.pdf'
				},
				{
					title: 'G+B Individual Portal (coming soon!)',
					description: 'Unsere Lösung komplett auf Ihre Wünsche angepasst',
					link: '',
					file: '',
					disabled: true
				},
				{
					title: 'G+B XXL Portal',
					description: 'Sie haben mehr als 2.500 aktive Teilnehmer? Dann sind Sie hier genau richtig',
					link: 'https://xxl-portal.eventvote.de',
					file: 'pdfs/Onepager_G+B_Streaming_Portal_XXL.pdf'
				},
				{
					title: 'G+B Event App',
					description: 'Eine App für alle Veranstaltungen',
					link: 'https://eventapp.eventvote.de',
					file: 'pdfs/G+B Event App Onepager 2023.pdf'
				}
			],
			/*
			footerLinks:
			[
				
				{
					text: 'Homepage',
					link: 'https://www.gb-mediensysteme.de/'
				},
				{
					text: 'Impressum',
					link: 'https://www.gb-mediensysteme.de/impressum/'
				},
				{
					text: 'Datenschutz',
					link: 'https://www.gb-mediensysteme.de/datenschutzerklaerung/'
				},
				{
					text: 'AGBs',
					link: 'https://www.gb-mediensysteme.de/agb/'
				}
			]
			*/
		}
	)
};
</script>

<style lang="scss">
font-face
{
	font-family: 'Ubuntu';
	src: url('fonts/Ubuntu/Ubuntu-R.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

*
{
	font-family: 'Ubuntu', Helvetica, sans-serif;
}

// hide scrollbar if not needed
html
{
	overflow-y: auto !important;
}

#app
{
	background: url('assets/background.png') no-repeat center center;
	background-size: cover;
}

/*
section:before
{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 87%;
	height: 100%;
	background: #001c31;
	transform: skew(-15deg);
	transform-origin: top;
}

@media (max-device-width: 1200px)
{
	section
	{
		background: #001c31;
	}
}
*/

.v-toolbar
{
	flex: none !important;
	background-color: #ffffff !important;
}

.logo
{
	align-self: center;
}

.portal-info
{
	//color: #ffffff;
	font-size: 2rem;
}

.portal-link
{
	//color: #ffffff;
	display: flex;
	align-items: center;
	
	&[disabled] div
	{
		color: #666666 !important;
	}
	
	&__prepend-icon
	{
		height: 64px;
		width: 64px;
		min-width: 64px;
		border-radius: 50%;
		background-color: #e30054;
	}
	
	&__append-icon
	{
		height: 32px;
		width: 32px;
		min-width: 32px;
		border-radius: 50%;
		background-color: #e30054;
	}
	
	&__title
	{
		font-size: 2rem;
	}
	
	&__description
	{
		color: #0094d5;
		font-size: 1.25rem;
	}
}

.portal-link + .portal-link
{
	margin-top: 2rem;
}

.links
{
	> div
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.link-divider
{
	background: #0094d5;
	border-radius: 50%;
	height: 5px;
	width: 5px;
}
</style>